import { useForcedLogin } from '@dreamstack/auth'
import {
  MainContainer,
  MainLayoutContextProvider,
} from '@dreamstack/feature-components'
import { SeoDefaults } from '@dreamstack/seo'
import { PageLoadIndicator } from '@dreamstack/simple-components'
import { TrackingSideEffects } from '@dreamstack/tracking'
import type { FunctionComponent } from 'react'
import tw from 'twin.macro'
import { ReservationNavBar } from './ReservationNavBar'

const Layout = tw.div`
  flex
  flex-col
  // space-y-8
  h-screen
`

export const ReservationMainLayout: FunctionComponent<React.PropsWithChildren<{}>> = ({ children }) => {
  const isLoggedIn = useForcedLogin()

  return (
    <MainLayoutContextProvider
      context={{ newsLetterFooter: undefined, contactFooter: undefined }}
    >
      <PageLoadIndicator />
      <TrackingSideEffects />
      <SeoDefaults
        siteTitle="Accentro Reservierungstool"
        enableSeoFollowIndex={false}
      />
      <Layout>
        <ReservationNavBar />
        <MainContainer tw="flex-1">{isLoggedIn && children}</MainContainer>
      </Layout>
    </MainLayoutContextProvider>
  )
}
