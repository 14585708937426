import { useAuthModalContext, useSessionTyped } from '@dreamstack/auth'
import { useTranslation } from '@dreamstack/i18n'
import { signOut } from 'next-auth/react'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import 'twin.macro'
import { StyledLink } from '../layout/NavBar'
import { useMyAccentroBaseUrl } from '../my/routing/useMyAccentroBaseUrl'

export const TopNavLogin: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const session = useSessionTyped()
  const { setOpen } = useAuthModalContext()
  const t = useTranslation()
  const myAccentroLink = useMyAccentroBaseUrl()
  return (
    <>
      {session ? (
        <div tw="space-x-px-8">
          <Link href={`/${myAccentroLink}`} passHref legacyBehavior>
            <StyledLink>{t('accentro:myAccentro')}</StyledLink>
          </Link>
          <StyledLink
            tw="cursor-pointer"
            onClick={() =>
              signOut({ callbackUrl: `${window.location.origin}` })
            }
          >
            {t('accentro:logout')}
          </StyledLink>
        </div>
      ) : (
        <div tw="space-x-px-8">
          <StyledLink tw="cursor-pointer" onClick={() => setOpen(true)}>
            {t('accentro:login')}/{t('accentro:register')}
          </StyledLink>
        </div>
      )}
    </>
  )
}
