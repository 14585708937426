import {
  MainContainer,
  NavBarLogo,
  TopNavLogin,
} from '@dreamstack/feature-components'
import type { FunctionComponent } from 'react'
import tw from 'twin.macro'

const NavBar = tw.div`
`

const Container = tw(MainContainer)`
  py-8
`

export const ReservationNavBar: FunctionComponent<React.PropsWithChildren<{}>> = () => {
  return (
    <NavBar>
      <Container>
        <div tw="flex flex-col md:flex-row items-center space-x-2">
          <NavBarLogo href="/reservations" forceDarkTextColor={true} />
          <div tw="flex-1" />
          <TopNavLogin />
        </div>
      </Container>
    </NavBar>
  )
}
